import React from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import BeatLoader from 'react-spinners/BeatLoader';

import { loadTracking } from '@united-talent-agency/julius-frontend-store';
import { downloadFile } from '../../../support/download';
import TableHeader from '../projects-view/views/search-projects/table-header';
import ListManager from '../../../components/list-manager/list-manager';
import PrintManager from '../../../components/print-manager/print-manager';
import CountLabel from '../../../components/count-label/count-label';
import Grid from '../../projects/projects-view/views/algolia/alg-projects-list/index';

class ProjectViewClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectAll: false,
      selection: new Set(),
      projects: [],
      isListManagerCollapsed: true,
      isPrintManagerCollapsed: true,
      view: 'list',
      sortColumn: 'Name',
      sortDirection: 1,
      isLoading: false,
    };
    this.show = notify.createShowQueue();
    this.getSelectedProjects = this.getSelectedProjects.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('#delete-modal');
  }

  // eslint-disable-next-line no-undef
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  // eslint-disable-next-line no-undef
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  loadTrackings = async () => {
    const { tracking } = this.props;
    const id = this.props.match.params.listId;
    const limit = tracking ? tracking.totalProjects : 5000;
    const url = `${id}?page=${0}&limit=${limit}`;
    const allProjects = await this.props.dispatch(loadTracking(url)).then(result => result.body);
    return allProjects;
  };

  print = (options = {}) => {
    const { tracking } = this.props;
    const { selection, selectAll } = this.state;
    const projects = Array.from(selection);
    this.performDownload(
      'v2/print-report',
      options.saveAs || tracking.name,
      {
        options,
        all: selectAll,
        projects,
      },
      [{ printType: 'list' }]
    );
  };

  // eslint-disable-next-line no-undef
  collapseEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: true });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isPrintManagerCollapsed: true });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  expandEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: false });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: false, isPrintManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: false });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  removeSelected = async () => {
    this.setState({ isLoading: true });

    const { tracking, onProjectsChanged } = this.props;
    const { selectAll, selection } = this.state;
    const isLimited = tracking?.totalProjects <= 100;
    const allProjects = isLimited ? tracking.projects : await this.loadTrackings();
    const projects = isLimited ? allProjects : allProjects.projects;
    const unselectedProjects = projects
      .filter(project => {
        return (selectAll && selection.has(project._id)) || (!selectAll && !selection.has(project._id));
      })
      .map(project => {
        return project._id;
      });
    this.setState({ selection: new Set() });
    onProjectsChanged && onProjectsChanged(unselectedProjects);
  };

  // eslint-disable-next-line no-undef
  toggleArchived = async () => {
    const { onArchivedChanged } = this.props;
    onArchivedChanged && onArchivedChanged();
  };

  // eslint-disable-next-line no-undef
  getSelectedProjects = () => {
    const { tracking } = this.props;
    const projects = tracking ? tracking.projects : [];
    const { selectAll, selection } = this.state;
    return projects
      .filter(project => {
        return (selectAll && !selection.has(project._id)) || (!selectAll && selection.has(project._id));
      })
      .map(project => {
        return project._id;
      });
  };
  // eslint-disable-next-line no-undef
  onSelectedChangedAll = selected => {
    this.setState({ selectAll: selected, selection: new Set() });
  };

  // eslint-disable-next-line no-undef
  onSelectedChanged = project => {
    const { selection } = this.state;
    if (selection.has(project)) {
      selection.delete(project);
    } else {
      selection.add(project);
    }
    this.setState({ selection });
  };

  // eslint-disable-next-line no-undef
  setIsDownloading = (url, isDownloading) => {
    this.setState({ isPrinting: isDownloading });
  };

  performDownload(url, fileName, options, query) {
    const { user, desk } = this.props;
    if (this.state.isPrinting) {
      return;
    }
    downloadFile(url, fileName, user, desk.current, this.setIsDownloading, options, query);
  }

  shareLinkInEmail(tracking) {
    const trackingLink = `${process.env.REACT_APP_PROJECTS_URL}/list/${tracking._id}`;
    const emailString = `mailto:?subject=${tracking.name}&body=${trackingLink}`;

    window.location.href = emailString;
  }

  onSortChanged = (column, direction) => {
    const { onSortChanged } = this.props;
    onSortChanged(column, direction);
  };

  getNewProjects = skip => {
    const { fetchNewProjects } = this.props;
    fetchNewProjects(skip);
  };

  render() {
    const { styles, tracking, user, dispatch, desk, sortColumn, sortDirection } = this.props;
    const deskCurrent = desk.current;
    const projects = tracking ? tracking.projects : [];
    const archived = tracking ? tracking.archived : false;

    const { isModalOpen, selection, selectAll, isListManagerCollapsed, isPrintManagerCollapsed } = this.state;
    const totalProjects = tracking ? tracking.totalProjects : 0;
    const isButtonDisabled =
      totalProjects === 0 || (!selectAll && selection.size === 0) || (selectAll && selection.size === totalProjects);
    const selectedCount = selectAll ? totalProjects - selection.size : selection.size;

    return (
      <React.Fragment>
        <CountLabel count={totalProjects} label="project" className={styles.countLabel} />
        <TableHeader
          buttons={[
            {
              name: 'Share',
              onClick: () => this.shareLinkInEmail(tracking),
              icon: 'share-alt',
              hidden: false,
              disabled: false,
            },
            {
              name: 'Print',
              onClick: () => this.expandEvent('print'),
              icon: this.state.isPrinting ? 'circle-o-notch fa-spin' : 'print',
              hidden: !isPrintManagerCollapsed,
              disabled: isButtonDisabled,
            },
            {
              name: 'Add to List',
              onClick: () => this.expandEvent('list'),
              icon: 'plus',
              hidden: !isListManagerCollapsed,
              disabled: isButtonDisabled,
            },
            {
              name: 'Remove',
              onClick: () => this.openModal(),
              icon: 'minus',
              disabled: isButtonDisabled,
            },
            {
              name: archived ? 'Unarchive' : 'Archive',
              onClick: this.toggleArchived,
              icon: 'archive',
            },
          ]}
        />
        <div className={styles.container}>
          <Grid
            isList={this.props.isList}
            projects={projects}
            totalProjects={totalProjects}
            selectAll={selectAll}
            selection={selection}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            getNewProjects={this.getNewProjects}
            onSelectedChanged={this.onSelectedChanged}
            onSelectedChangedAll={this.onSelectedChangedAll}
            onSortChanged={(column, direction) => this.onSortChanged(column, direction)}
          />
          {isModalOpen && (
            <Modal
              contentLabel="Remove Selected"
              onRequestClose={this.closeModal}
              isOpen={isModalOpen}
              shouldCloseOnOverlayClick={false}
              style={{
                content: {
                  top: '50%',
                  left: '50%',
                  bottom: 'auto',
                  transform: 'translate(-50%, -50%)',
                  overflow: 'hidden',
                  fontFamily: 'aktiv-grotesk',
                  height: '250px',
                  width: '700px',
                  borderRadius: '10px',
                  color: '#4a4a4a',
                  padding: '10px 20px',
                  border: '1px solid #141414',
                },
              }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center border-bottom w-100 pb-2">
                  <div></div>
                  <div style={{ fontSize: '20px' }}>Remove Selected</div>
                  <ImCross style={{ cursor: 'pointer' }} onClick={!this.state.isLoading ? this.closeModal : null} />
                </div>
                <div className="py-3">
                  <div>
                    Are you sure you want to remove {selectedCount} of {totalProjects} items?
                  </div>
                  <div className="d-flex flex-row-reverse pt-3">
                    <Button
                      className="border border-dark ml-3"
                      outline
                      onClick={this.closeModal}
                      disabled={this.state.isLoading}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: '0.85em', fontWeight: 'bold', lineHeight: 1, textTransform: 'uppercase' }}
                      >
                        <div className="py-1">cancel</div>
                      </div>
                    </Button>
                    <Button
                      className="border border-dark"
                      outline
                      bsStyle="danger"
                      onClick={this.removeSelected}
                      disabled={this.state.isLoading}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: '0.85em', fontWeight: 'bold', lineHeight: 1, textTransform: 'uppercase' }}
                      >
                        <div className="py-1">Remove</div>
                      </div>
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center py-2">
                    <BeatLoader
                      color={'#141414'}
                      loading={this.state.isLoading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {!isListManagerCollapsed && (
            <div className={styles.listColumn}>
              <ListManager
                desk={deskCurrent}
                user={user}
                dispatch={dispatch}
                projectsFunc={this.getSelectedProjects}
                onCancel={() => {
                  this.collapseEvent('list');
                }}
                onCreated={list => {
                  this.collapseEvent('list');
                  this.show(`${list.name} created`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                  window.open(`/list/${list._id}`, list._id);
                }}
                onUpdated={() => {
                  this.collapseEvent('list');
                  this.show(`List updated`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                }}
              />
            </div>
          )}
          {!isPrintManagerCollapsed && (
            <div className={styles.listColumn}>
              <PrintManager
                collapseEvent={() => this.collapseEvent('print')}
                print={this.print}
                userInTalent={user && user.personId && user.personId.department === 'Talent'}
                showSortOptions={true}
              />
            </div>
          )}
        </div>
        <div id="delete-modal" />
      </React.Fragment>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '-10px',
  },

  listColumn: {
    minWidth: '300px',
  },
  section: {
    marginBottom: 20,
  },
  countLabel: { marginLeft: 30, color: '#666', fontSize: '10pt', float: 'left' },
});

const mapStateToProps = state => state;

const ProjectView = connect(mapStateToProps)(withStyles(ProjectViewClass));
export { ProjectView };
